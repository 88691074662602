.flex {
  display: flex;
}

.justify-space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.unstyled-list {
  list-style: none;
}

.ml-auto {
  margin-left: auto;
}

.mx-1{
  margin-left: 1rem;
  margin-right: 1rem;
}

.pl-0{
  padding-left: 0;
}

.m-0_5{
  margin: 0.5rem;
}