.anchor a{
    font-family: var(--font-primary) ;
    text-decoration: none;
    padding: 10px 10px;
    position: relative;
    color: var(--text-primary-color);
}

.anchor{
    position: relative;
}

.hover__effect{
    width: 100%;
    background-color: var(--hover);
    opacity: 0;
    bottom: -50%;
    border-radius: 3px;
    box-shadow: var(--hover-effect);
    position: absolute;
    height: 0;
    left: 0;
    transition: all 0.25s;
}

